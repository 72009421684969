@tailwind base;
@tailwind components;
@tailwind utilities;

.layoutContainer{
  min-height: (100vh - 50px);
}

.prova_img{
  background-image: url('./prova_img.jfif');
  background-position: center;
  background-contain:contain;
  background-size:cover;
}

.prova_color{
  background-image: url('./cyan_leather.png');
  background-position: center;
  background-contain:contain;
  background-size:cover;
}

.prova_sfondo{
  background-image: url('./prova_sfondo.png');
  background-position: center;
  background-contain:contain;
  background-size:cover;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height:10px;
}

/* Track */
::-webkit-scrollbar-track {
  
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 8px;
  height: 10px;
}

.noScrollBar{
  
}

.rightZero{
  position: relative;
  right: 0px;
}

.fullWidth{
  width:100%;
}

.mce-content-body{
  background: #000000
}

@layer components{

.btn {
    @apply font-bold py-2 px-4 rounded;
  }
  .btn-blue {
    @apply bg-blue-500 text-white;
  }
  .btn-blue:hover {
    @apply bg-blue-700;
  }
 
 .BUTTON_CLASS{@apply
  min-h-[40px] 
  min-w-[100px] 
  rounded-[7px] 
  border-[3px] border-black
  hover:text-[white]
  hover:bg-[gray] hover:border-[gray] hover:scale-[1.05]
  active:scale-[0.95]
  duration-[100ms]
  font-semibold
  flex flex-row items-center justify-center
 }
;
.contenitore{@apply
  flex flex-row overflow-hidden
}

 @media (max-width: 767px) {
  .contenitore{@apply
    flex flex-col
  }
}
}
